<template>
  <div class="main-content">
    <div class="banner">
      <a-carousel autoplay>
        <div v-for="(item, i) in bannerList" :key="i">
          <div class="bannerbox clear">
            <img :src="item.imgs" slot="icon" alt="" />
            <div class="bannerText clear">
              <div class="tit">
                {{ item.tit }}
              </div>
              <div class="cont">
                {{ item.cont }}
              </div>
              <!-- 被干掉的了解详情按钮 -->
              <!-- <div class="Dtil">
                <span @click="toAbout"> 了解详情&nbsp;&nbsp;&nbsp;> </span>
              </div>
              <div class="mark"></div> -->
            </div>
          </div>
        </div>
      </a-carousel>
    </div>
    <div class="serviceCentre">
      <div class="centre">
        <div class="CenterTitle">
          <div class="tit">服务中心</div>
          <div>Service Centre</div>
        </div>
        <div class="moreCenter">
          <div class="centerList">
            <div>
              <img src="../assets/img1.png" alt="" />
            </div>
            <div>基础数据处理服务中心</div>
            <div>
              为政府机关、公共图书馆、出版社、医院、科研院所、高等院校等企事业单位，提供基础数据的加工处理、清洗、打通数据壁垒，为“数据多跑路，人民少跑路”打下坚实基础。
            </div>
            <div>
              <router-link to="/solution/basic_data?tab=1"
                >了解更多</router-link
              >
            </div>
          </div>
          <div class="centerList">
            <div>
              <img src="../assets/img2.png" alt="" />
            </div>
            <div>数据应用研发服务中心</div>
            <div>
              为各行业系统单位提供数据中心、数据中台的解决方案与实施，完成数据分析、智能应用，打通数据之间的关联关系，辅助政府决策，更有效的协助项目落地。
            </div>
            <div>
              <router-link to="/solution/data_apply?tab=2"
                >了解更多</router-link
              >
            </div>
          </div>
          <div class="centerList">
            <div>
              <img src="../assets/img3.png" alt="" />
            </div>
            <div>业务场景综合应用中心</div>
            <div>
              为各行业提供丰富的资源组合、解析数字化运营，专注具体行业、领域和细分场景中的问题，匹配个性化场景解决方案，彻底解决各行工作流程上的壁垒。
            </div>
            <div>
              <router-link to="/solution/business_scenes?tab=3"
                >了解更多</router-link
              >
            </div>
          </div>
          <div class="centerList">
            <div>
              <img src="../assets/img4.png" alt="" />
            </div>
            <div>智慧云服务中心</div>
            <div>
              为全国县级政府提供生产管理、客户资源管理等项目开发，实现信息共享、凝聚共识、供需衔接、汇聚资源、全民互动，优化城市管理和服务，改善市民生活质量，打造“智慧县城”。
            </div>
            <div>
              <router-link to="/solution/cloud?tab=4">了解更多</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="carousel">
      <a href="http://jimeng.sozdata.com/#/login" target="_blank">
        <img src="../assets/banner5.png" style="width:100%;height: 100%;" alt="">
      </a>
    </div>
    <div class="newsInformation">
      <div class="news">
        <div class="newsTitle">
          <div class="tit">新闻动态</div>
          <div>News lnformation</div>
        </div>
        <div class="newsBanner">
          <!-- 轮播左侧图片 -->
          <div class="newsCont">
            <a-carousel arrows :afterChange="handleChange">
              <div
                slot="prevArrow"
                class="custom-slick-arrow"
                style="left: 45px; zindex: 1"
              >
                <!-- <a-icon class="leftCli" type="left-circle" /> -->
                <img src="@/assets/cm_left.png" alt="" class="cm_left" />
              </div>
              <div
                slot="nextArrow"
                class="custom-slick-arrow"
                style="right: 90px"
              >
                <!-- <a-icon class="rightCli" type="right-circle"/> -->
                <img src="@/assets/cm_right.png" alt="" class="cm_right" />
              </div>
              <div class="bannerImg" v-for="(item, i) in newsList" :key="i">
                <img :src="item.imgUrl" alt="" />
              </div>
            </a-carousel>
          </div>
          <!-- 轮播右侧文字 -->
          <div class="bannerText">
            <div class="tit">{{ newsList[activeClass].title}}</div>
            <div class="cont">
              {{ newsList[activeClass].summary.length>194?newsList[activeClass].summary.substring(0,194)+'...':newsList[activeClass].summary}}
            </div>
            <div class="times">
              <img src="@/assets/t.png" alt="" />
              {{ newsList[activeClass].pubtime }}
            </div>
            <div class="more" @click="newdtils(newsList[activeClass].newsId)">
               <div><router-link to="/news">了解更多</router-link></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="typicalCase">
      <div class="case">
        <div class="caseTitle">
          <div class="tit">典型案例</div>
          <div>Typical Case</div>
        </div>
        <div class="moreCenter">
          <ul class="centerList">
            <li>
              <div>
                <img src="../assets/img11.png" alt="" />
              </div>
              <div>财政部一中国财政基础数据库<br />系统项目</div>
              <div>
                中国财政基础数据库系统是一套通过数据化加工和算法程序开发技术相融合建设的，包含多维检索、分析报告输出、可视化展示等功能，服务于经济相关高校、科研院所等相关专业人士。
              </div>
              <div><router-link to="/about/case">了解更多</router-link></div>
            </li>
            <li>
              <div>
                <img src="../assets/img22.png" alt="" />
              </div>
              <div>脱贫攻坚数字化<br />成果项目</div>
              <div>
                2020年新讯科技受湖南省桃源县政府委托，对县内漳江镇、浔阳镇、陬市镇等若干个乡镇的扶贫档案进行了专业数字化加工，此项目类别涉及产业扶贫、教育扶贫、就业扶贫等若干类。
              </div>
              <div><router-link to="/about/case">了解更多</router-link></div>
            </li>
            <li>
              <div>
                <img src="../assets/img33.png" alt="" />
              </div>
              <div>退役军人事务局<br />加工项目</div>
              <div>
                根据《国务院办公厅关于做好退役军人和其他优抚对象信息采集工作的通知》2020年承接了湖南省桃源县退役军人事务局的退役军人档案信息安全数字化加工业务，以8个月完成交付。
              </div>
              <div><router-link to="/about/case">了解更多</router-link></div>
            </li>
            <li>
              <div>
                <img src="../assets/img44.png" alt="" />
              </div>
              <div>国家图书馆公有领域图书数字化<br />加工服务项目</div>
              <div>
                2019年，新讯在此项目招投标中脱颖而出，获得了该项目的加工实施权。以驻场扫描、异地处理等加工方式，在9个月的工期里，高标准、高质量的完成了百万页图书数字化加工工程。
              </div>
              <div><router-link to="/about/case">了解更多</router-link></div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="serviceArea">
      <div class="area">
        <div class="areaTitle">
          <div class="tit">服务领域</div>
          <div>Service Area</div>
        </div>
        <div class="areaimg">
          <div class="img1 fl">
            <a>
              <img
                style="width: 550px; height: 180px"
                src="../assets/field1.png"
                alt=""
              />
              <div class="markArea">
                <span>政府</span>
              </div>
            </a>
            <a>
              <img
                style="
                  width: 330px;
                  height: 180px;
                  margin-left: 20px;
                  margin-right: 20px;
                "
                src="../assets/field2.png"
                alt=""
              />
              <div class="markArea2">
                <span>出版社</span>
              </div>
            </a>
            <a>
              <img
                style="width: 279px; height: 180px"
                src="../assets/field3.png"
                alt=""
              />
              <div class="markArea3">
                <span>医院</span>
              </div>
            </a>
          </div>
          <div class="img2">
            <a>
              <img
                style="width: 279px; height: 180px"
                src="../assets/field4.png"
                alt=""
              />
              <div class="markArea">
                <span>科研院所</span>
              </div>
            </a>
            <a>
              <img
                style="
                  width: 330px;
                  height: 180px;
                  margin-left: 20px;
                  margin-right: 20px;
                "
                src="../assets/field5.png"
                alt=""
              />
              <div class="markArea3">
                <span>高等院校</span>
              </div>
            </a>
            <a>
              <img
                style="width: 550px; height: 180px"
                src="../assets/field6.png"
                alt=""
              />
              <div class="markArea2">
                <span>档案馆/图书馆等</span>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="aboutNews">
      <div class="news">
        <div class="newsTitle">
          <div class="tit">关于新讯</div>
          <div>About Us</div>
        </div>
        <div class="moreCenter">
          <div class="centFlet">
            <div class="tit">关于新讯</div>
            <div class="cont">
              <div>
                湖南新讯信息科技有限公司（以下简称新讯科技）成立于2017年，是一家集研发、生产、销售于一体的大数据企业。公司位于湖南省桃源县高新技术产业开发区，占地面积50余亩，园区建设包括基础数据处理服务中心、数据应用研发服务中心、业务场景综合应用中心、智慧云服务中心，致力为客户提供专业的数据处理、技术开发及数据解决方案。
              </div>
              <div>
                公司自成立以来，始终秉持以数据为基础，注重技术及数据储备能力投入，为政府机关、教育科研机构、金融机构、企事业单位等提供数据加工、数据应用、数据可视化、数据分析、数据云等一体化服务。其基础数据处理中心拥有先进的专业技术、一流的专业设备、高效的管理经验和一支专业化的管理队伍，特别在专业处理报刊、档案、图书、文献等大型数字化工程及全文检索制作等方面拥有独特的优势。
              </div>
            </div>
            <div class="more">
              <router-link to="/about/intro?side=4"
                >了解更多 <img src="../assets/more.png" alt=""
              /></router-link>
            </div>
          </div>
          <div class="centR">
            <a-carousel :after-change="onChange">
              <div class="imgs">
                <img src="@/assets/us1.png" alt="" />
              </div>
            </a-carousel>
          </div>
        </div>
      </div>
    </div>
    <div class="typicalCustomer">
      <div class="customer">
        <div class="customerTitle">
          <div class="tit">典型客户</div>
          <div>Typical Customer</div>
        </div>
        <div class="customUrl">
          <!-- 财政部 -->
          <div class="imgs">
            <img src="../assets/1.png" alt="" />
          </div>
          <!-- 人民政府 -->
          <div class="imgs">
            <img src="../assets/2.png" alt="" />
          </div>
          <!-- 蒙纳士 -->
          <div class="imgs">
            <img src="../assets/7.png" alt="" />
          </div>
          <!-- 哈弗 -->
          <div class="imgs">
            <img src="../assets/8.png" alt="" />
          </div>
          <!-- 残联 -->
          <div class="imgs">
            <img src="../assets/5.png" alt="" />
          </div>
          <!-- 国家图书馆 -->
          <div class="imgs">
            <img src="../assets/6.png" alt="" />
          </div>
          <!-- 北大 -->
          <div class="imgs">
            <img src="../assets/4.png" alt="" />
          </div>
          <!-- 清华 -->
          <div class="imgs">
            <img src="../assets/3.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // bannerimageUrl: "../assets/banner0.png",
      activeClass: 0,
      // 首页banner数据
      bannerList: [
        {
          id: 0,
          imgs: require("@/assets/banner0.png"),
          tit: "大数据产业链服务商",
          cont: "为客户提供专业的数据处理、技术开发、数据解决方案，是一家集研发、生产、销售于一体的大数据产业链服务商。",
        },
      ],
      newsList: [{ title: "" ,summary:''}],
    };
  },
  mounted() {
    this.$http.getNewList().then((res) => {
      if (res.success) {
        this.newsList = res.homeNewList;
      }
    });
  },
  methods: {
    //点击了解详情跳转到关于我们
    toAbout() {
      this.$router.push("/about");
    },
    handleChange(current) {
      this.activeClass = current;
    },
    afterChange(con) {
      // console.log(con);
    },
    newdtils(id) {
      let routeUrl = this.$router.resolve({
        path: "/news/detail",
        query: { newsId: id },
      });
      window.open(routeUrl.href, "_blank");
    },
    onChange(a, b, c) {
      // console.log(a, b, c);
    },
  },
};
</script>
<style lang="scss" scoped>
/* For demo */
// .ant-carousel >>> .slick-slide {
//   text-align: center;
//   line-height: 160px;
//   overflow: hidden;
// }
.ant-carousel >>> .custom-slick-arrow {
  opacity: 0.3;
}
.ant-carousel >>> .custom-slick-arrow:before {
  display: none;
}
.ant-carousel >>> .custom-slick-arrow:hover {
  opacity: 0.5;
}

.ant-carousel >>> .slick-slide h3 {
  color: #fff;
}
</style>